import { render, staticRenderFns } from "./roleFunctionTable.vue?vue&type=template&id=49189139&"
import script from "./roleFunctionTable.vue?vue&type=script&lang=js&"
export * from "./roleFunctionTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/_vuetify-loader@1.7.3@vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VDataTable,VSelect})
