<template>
  <v-data-table
    :headers="head"
    :items="data"
    mobile-breakpoint="960"
    fixed-header
    :height="data.length > 5 ? '400' : ''"
    class="elevation-6"
    :hide-default-footer="true"
    :search="search"
    :server-items-length="totalItems"
    :single-select="false"
    show-select
    v-model="selectedFunc"
  >
    <template v-slot:[`item.list`]="{ item }">
      <v-checkbox
        v-if="item.list_show"
        :input-value="item.list"
        :disabled="item.disable"
        v-model="item.list"
      />
    </template>

    <template v-slot:[`item.add`]="{ item }">
      <v-checkbox
        v-if="item.add_show"
        :input-value="item.add"
        :disabled="item.disable"
        v-model="item.add"
      />
    </template>

    <template v-slot:[`item.del`]="{ item }">
      <v-checkbox
        v-if="item.del_show"
        :input-value="item.del"
        :disabled="item.disable"
        v-model="item.del"
      />
    </template>

    <template v-slot:[`item.modify`]="{ item }">
      <v-checkbox
        v-if="item.modify_show"
        :input-value="item.modify"
        :disabled="item.disable"
        v-model="item.modify"
      />
    </template>

    <template v-slot:[`item.row`]="{ item }">
      <v-checkbox
        v-if="item.row_show"
        :input-value="item.row"
        :disabled="item.disable"
        v-model="item.row"
      />
    </template>

    <template v-slot:[`item.column`]="{ item }">
      <v-checkbox
        v-if="item.column_show"
        :input-value="item.column"
        :disabled="item.disable"
        v-model="item.column"
      />
    </template>

    <template v-slot:[`item.row_choice`]="{ item }">
      <v-select
        v-if="item.rowList.length > 0"
        :label="$vuetify.lang.t('$vuetify.pleaseChoose')"
        :items="item.rowList"
        v-model="item.row_choice"
      />
    </template>

    <template v-slot:[`item.column_choice`]="{ item }">
      <v-select
        v-if="item.columnList.length > 0"
        :label="$vuetify.lang.t('$vuetify.pleaseChoose')"
        :items="item.columnList"
        v-model="item.column_choice[0]"
      />
    </template>

    <template v-slot:[`item.note`]="{ item }">
      {{ checkStrLength(item.note) }}
      <span class="blue--text" v-if="item.note.length > 30">.....more</span>
    </template>

    <template v-slot:[`item.address`]="{ item }">
      {{ checkStrLength(item.address) }}
      <span class="blue--text" v-if="item.address.length > 30">.....more </span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "roleFunctionTable",
  props: {
    head: Array,
    data: Array,
    search: String,
    dialog: Boolean,
    totalItems: Number,
  },
  data() {
    return {
      selectedFunc: [],
      generalRule: [(v) => !!v || "this field is required"],
    };
  },
  watch: {
    data() {
      this.selectedFunc = [];
    },
    selectedFunc() {
      if (this.selectedFunc.length > 0) {
        // this.selectedFunc[this.selectedFunc.length - 1].disable = true;

        this.selectedFunc.forEach((selectedObj) => {
          if (selectedObj.disable) {
            selectedObj.list = true;
          }
          selectedObj.disable = false;

          this.data.forEach((obj) => {
            if (selectedObj.id == obj.id) {
              obj = selectedObj;
            }
          });
        });
      } else {
        this.data.forEach((obj) => {
          obj.disable = true;
          obj.list = false;
        });
      }
    },
    checkStrLength(item) {
      if (item.length > 30) {
        return item.substring(0, 30);
      } else {
        return item;
      }
    },
  },
};
</script>
